import { render, staticRenderFns } from "./AwardsDetails.vue?vue&type=template&id=4b438953&scoped=true"
import script from "./AwardsDetails.vue?vue&type=script&lang=js"
export * from "./AwardsDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b438953",
  null
  
)

export default component.exports