<template>
  <div class="reward-preview">
    <!--  HEADER  -->
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title">{{ texts.previewPage.title }}</h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>
    <CTabs class="zq--tabs" :active-tab="activeTab" @update:activeTab="setActiveTab">
      <CTab title="Reward">
        <RewardDetails />
      </CTab>
      <CTab :title="texts.previewPage.awardsTab">
        <AwardsDetails v-if="activeTab === 1" />
      </CTab>
<!--      <CTab :title="texts.previewPage.rewardTypeTab" v-if="Object.keys(reward).length">-->
<!--        <RewardTypeDetails-->
<!--          :rewardTypeId="reward.rewardType.id"-->
<!--        />-->
<!--      </CTab>-->
    </CTabs>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import IconWithTooltip from '@/shared/UI/IconWithTooltip';
  // import RewardTypeDetails from "@/views/ziqni/rewards/components/RewardTypeDetails";
  import AwardsDetails from './AwardsDetails';
  import RewardDetails from "@/generated/ziqni/views/rewards/RewardDetails";
  import {rewards} from "@/config/descriptions/rewards"
  import {rewardsTexts} from "@/config/pageTexts/rewards.json";

  export default {
    name: "PreviewReward",
    components: {
      IconWithTooltip,
      AwardsDetails,
      // RewardTypeDetails,
      RewardDetails,
    },
    data() {
      return {
        texts: {
          ...rewardsTexts
        },
        descriptions: {
          ...rewards.preview
        },
        activeTab: 0,
      }
    },
    computed: {
      ...mapGetters('rewards', ['reward']),
    },
    methods: {
      setActiveTab(val) {
        this.activeTab = val;
      },
    }
  }
</script>

<style lang="scss">
  .reward-preview {
    height: calc(100% - 50px);
    .content {
      background: var(--zq-sub-bg);
      border-top: 1px solid var(--zq-content-border-color);
    }
    .detail-label {
      @media (min-width: 420px) {
        max-width: 220px;
      }
    }
    .nxt-col-mobile {
      @media (max-width: 420px) {
        text-align: center;
      }
    }
    .overview-label {
      font-style: italic;
      color: darkgray;
    }
    .files-metadata--list {
      margin: 0;
      padding: 0;
      font-weight: bold;
    }
  }
</style>
