<template>
  <div class="search-container">
    <CInput
        label
        id="zq--search-field"
        v-model="search"
        class="m-0 search-select"
        :placeholder="$t(placeholder)"
        @keyup="searchUpdate"
    />
    <label v-if="!search" class="search-clear" for="zq--search-field">
      <img src="../../../../public/img/icons/search.svg" alt="search">
    </label>
    <div v-if="search" class="search-clear" @click="resetSearch">
      <i class="fa fa-times"></i>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ClSearch',
  props: {
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      search: ''
    }
  },
  methods: {
    searchUpdate({target: {value}}) {
      this.search = value;
    },
    resetSearch() {
      this.search = '';
    }
  },
  watch: {
    search(val) {
      this.$emit('searchUpdate', val);
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins";

.search-container {
  position: relative;
  min-width: 300px;

  .search-clear {
    position: absolute;
    top: 6px;
    right: 2%;
    cursor: pointer;
  }

  @include media-breakpoint-down('576px') {
    //margin: 16px 0;
    min-width: 200px;
  }
}

.search-select {
  width: 100%;
}

</style>